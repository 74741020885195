import * as React from "react"
import { Link } from 'gatsby';
import { styled } from "@mui/material/styles";

import Layout from '../components/Layout';
import { Title, Text as CustomText } from '../mui-elements/shared';
import { SEO } from '../components/SEO';

const Text = styled(CustomText)({
  textAlign: 'justify',
}) as typeof CustomText;

import {
  Wrapper,
  Section
} from '../style/pages/footer-pages';
import RequestAccessForm from "../components/Forms/requestAccessForm";

export default function RequestAccess() {
  return (
    <Layout>
      <Wrapper>
        <Section>
          <Title component="h2" variant="h2">
            Request Access
          </Title>
          <Text>
            Discuss your needs and submit access requests to our commercial 
            department.
          </Text>
        </Section>
        <Section>
          <Title component="h2" variant="h2">
            I want access to the RESTful API
          </Title>
          <Text>
            If you have already purchased Voltaware's sensors and have 
            provisioned them, you should be able to access the RESTful API with 
            each sensor's user id and password. You should have received an email 
            with a back-end host url. Read more on how to authenticate and 
            access all endpoints available <Link to="/integrations/voltaware-sensors">here</Link>.
          </Text>
        </Section>
        <Section>
          <Title component="h2" variant="h2">
            I want access to the Messaging Bus
          </Title>
          <Text>
            If you have requested access you should have received the following 
            information: username, password, queue name and host. Read more 
            on how to connect to Voltaware's messaging bus <Link to="/integrations/voltaware-sensors">here</Link>.<br/>
          </Text>
          <Text mt={1}>
            If you would like to request a sensor batch or a new access please 
            fill in the form below:
          </Text>
        </Section>
        <RequestAccessForm />
      </Wrapper>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
